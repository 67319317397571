import React from 'react';
import { Link, navigate } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';

import Button from '@mui/material/Button';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as Styles from './DesktopMenu.module.scss';

const AccountMenu = () => {
	const { user, logoutUser } = useIdentityContext();

	const handleLogout = async () => {
		await logoutUser();
		navigate('/');
	};

	if (user) {
		return (
			<div className={Styles.navbarItemDropdown}>
				<Button variant="navigation" className={Styles.navbarItemDropdownButton}>
					Account
					<ExpandMoreIcon color="primary" />
				</Button>

				<div className={Styles.navbarItemDropdownMenu} style={{ right: '0' }}>
					<div>
						<Button variant="navigation" component={Link} to="/account/dashboard">
							Dashboard
						</Button>
					</div>
					<div>
						<Button variant="navigation" onClick={handleLogout}>
							Log Out
						</Button>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<Button
				variant="navigation"
				component={Link}
				className={Styles.navbarItemDropdownButton}
				to="/users/sign_in">
				Register/Log in
			</Button>
		);
	}
};

export default AccountMenu;
