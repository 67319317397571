import React, { useContext } from 'react';
import { Link } from 'gatsby';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

import Logo from './Logo';
import AccountMenu from './AccountMenu';

import { pushEvent, GIFTCARD_EVENT_CATEGORY, GIFTCARD_EXIT } from '../analytics/TagManager';

import DesktopMenuDropdown from './DesktopMenuDropdown';
import * as Styles from './DesktopMenu.module.scss';

import { CartStateContext } from '../../contexts/cart';

const DesktopMenu = () => {
	const { items: cartItems } = useContext(CartStateContext);

	const lessonsMenuItems = [
		{ text: 'Online Dance Lessons', url: '/online-dance-lessons/' },
		{ text: 'Ballroom Dance Lessons', url: '/ballroom-dancing/' },
		{ text: 'Couples Dance Lessons', url: '/couples-dance-lessons/' },
		{ text: 'Teen Dance Lessons', url: '/teen-dance/' },
		{ text: 'Competitive Dancesport Lessons', url: '/competitive-dancesport-lessons/' },
		{ text: 'Wedding Dance Lessons', url: '/wedding-dance/' },
		{ text: 'Wheelchair Dance Lessons', url: '/wheelchair-dance-lessons/' },
	];

	const locationsMenuItems = [
		{ text: 'Morgantown', url: '/locations/morgantown-west-virginia/' },
	];

	const aboutMenuItems = [
		{ text: 'About Us', url: '/about/' },
		{ text: 'Blog', url: '/blog/' },
		{ text: 'Gallery', url: '/gallery/' },
		{ text: 'Contact', url: '/contact/' },
	];

	const buttonClick = async (event: any) => {
		event.preventDefault();
		pushEvent(GIFTCARD_EVENT_CATEGORY, GIFTCARD_EXIT, 'Gift Card Exit', 1);

		if (typeof window !== 'undefined') {
			window.location.href =
				'https://squareup.com/gift/BYCK343H5W59N/order?fbclid=IwAR0QMydWyl7qvxRkeA_oopOSTj15_l-FuQZvNBavk5Pm_GbHuXwixikNGb0';
		}
	};

	return (
		<>
			<nav className={Styles.accountBar} role="navigation" aria-label="account">
				<Button
					variant="navigation"
					component="a"
					target="_blank"
					rel="noopener noreferrer"
					href="https://squareup.com/gift/BYCK343H5W59N/order?fbclid=IwAR0QMydWyl7qvxRkeA_oopOSTj15_l-FuQZvNBavk5Pm_GbHuXwixikNGb0"
					onClick={buttonClick}>
					Gift Cards
				</Button>
				<AccountMenu />
			</nav>
			<nav className={Styles.navbar} role="navigation" aria-label="main">
				<div className={Styles.navbarBrand}>
					<Logo />
				</div>
				<div id="main-navbar" className={Styles.navbarMenu}>
					<div className={Styles.navbarStart}>
						<DesktopMenuDropdown
							buttonText="Lessons"
							dropdownItems={lessonsMenuItems}
						/>
						<DesktopMenuDropdown
							buttonText="Locations"
							dropdownItems={locationsMenuItems}
						/>
						<DesktopMenuDropdown buttonText="About" dropdownItems={aboutMenuItems} />
						<Button
							variant="navigation"
							component={Link}
							className={Styles.navbarItemDropdownButton}
							to="/classes-and-events/">
							Events
						</Button>
						<Button
							variant="navigation"
							component={Link}
							className={Styles.navbarItemDropdownButton}
							to="/podcast/">
							Podcast
						</Button>
					</div>

					<div className={Styles.navbarEnd}>
						<div
							style={{ display: 'flex', alignItems: 'center', paddingRight: '1rem' }}>
							<Button
								variant="contained"
								color="primary"
								component={Link}
								to="/schedule-lesson/">
								Book a Lesson
							</Button>
						</div>
						{cartItems && cartItems.length > 0 && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									paddingRight: '1rem',
								}}>
								<Button as={Link} to="/cart">
									<Badge
										badgeContent={
											cartItems && cartItems.length > 0 ? cartItems.length : 0
										}
										color="primary">
										<WorkOutlineIcon />
									</Badge>
								</Button>
							</div>
						)}
					</div>
				</div>
			</nav>
		</>
	);
};

export default DesktopMenu;
