import React, { useState } from 'react';
import { Link } from 'gatsby';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ExpandMore from '@mui/icons-material/ExpandMore';

import * as Styles from './DesktopMenu.module.scss';

const DesktopMenuDropdown = ({ buttonText, dropdownItems }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [mouseOverButton, setMouseOverButton] = useState(false);
	const [mouseOverMenu, setMouseOverMenu] = useState(false);
	const open = Boolean(mouseOverButton || mouseOverMenu);

	const toggleMouseOverButtonTrue = (event) => {
		setAnchorEl(event.currentTarget);
		setMouseOverButton(true);
	};

	const toggleMouseOverButtonFalse = () => {
		setTimeout(() => {
			setMouseOverButton(false);
		}, 100);
	};

	const toggleMouseOverMenuTrue = (event) => {
		setMouseOverMenu(true);
	};

	const toggleMouseOverMenuFalse = () => {
		setTimeout(() => {
			setMouseOverMenu(false);
		}, 100);
	};

	const handleClose = () => {
		setMouseOverButton(false);
		setMouseOverMenu(false);
		setAnchorEl(null);
	};

	return (
		<div className={Styles.navbarItemDropdown}>
			<Button
				id={`${buttonText}-button`}
				variant="navigation"
				aria-controls={open ? `${buttonText}-menu` : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onMouseOver={toggleMouseOverButtonTrue}
				onMouseLeave={toggleMouseOverButtonFalse}
				onClick={anchorEl ? handleClose : toggleMouseOverButtonTrue}
				className={Styles.navbarItemDropdownButton}>
				{buttonText}
				<ExpandMore color="primary" />
			</Button>
			<Menu
				id={`${buttonText}-menu`}
				anchorEl={anchorEl}
				open={open}
				MenuListProps={{
					onMouseEnter: toggleMouseOverMenuTrue,
					onMouseLeave: toggleMouseOverMenuFalse,
					'aria-labelledby': `${buttonText}-button`,
				}}>
				{dropdownItems &&
					dropdownItems.length > 0 &&
					dropdownItems.map((item, index) => (
						<MenuItem
							key={index}
							onClick={handleClose}
							component={Link}
							className={Styles.desktopMenuDropdownText}
							to={item.url || '/'}>
							{item.text}
						</MenuItem>
					))}
			</Menu>
		</div>
	);
};

export default DesktopMenuDropdown;
